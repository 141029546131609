import React, {useState, useEffect, useRef} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {Card} from 'primereact/card';
import {useHistory} from "react-router-dom";
import AppLink, {ImageLink} from "../components/Link";
import {Toast} from "primereact/toast";
import {ConfirmPopup, confirmPopup} from 'primereact/confirmpopup';

export default function CustomersList() {
    const toast = useRef(null);
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const [doctorData, setDoctorData] = useState([]);
    useEffect(() => {
        LoadData();
    }, [])

    const showSuccess = (msg) => {
        toast.current.show({severity: 'success', summary: 'Success', detail: msg, life: 3000});
    }
    const showError = () => {
        toast.current.show({severity: 'error', summary: 'Error', detail: 'Server Error', life: 3000});
    }

    const LoadData = async () => {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formdata = new FormData(this);
        formdata.append("page", "LoadCustomers");
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}Customer.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                if (result.status == "1") {
                    let Data = result.data;
                    setDoctorData(Data);
                    setLoading(false);
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }

    return (
        <section className="content">
            <Card
                title={"Patient List"} className="card">
                <Toast ref={toast}/>
                <ConfirmPopup/>
                <DataTable value={doctorData} loading={loading} emptyMessage={'No Record Found !'} paginator
                           rows={10}
                           rowsPerPageOptions={[5, 10, 25, 50]}
                           tableStyle={{minWidth: '50rem', overflow: "auto"}}>
                    <Column field="Name" header="Full Name" style={{minWidth: '12rem'}}/>
                    <Column field="Email" header="Email" style={{minWidth: '12rem'}}/>
                    <Column field="ContactNo" header="Contact No" style={{minWidth: '12rem'}}/>
                    <Column field="CNIC" header="CNIC" style={{minWidth: '12rem'}}/>
                    <Column field="Country" header="Country" style={{minWidth: '12rem'}}/>
                    <Column field="State" header="State" style={{minWidth: '12rem'}}/>
                    <Column field="City" header="City" style={{minWidth: '12rem'}}/>
                    <Column field="Address" header="Address" style={{minWidth: '12rem'}}/>
                    <Column field="Date" header="Date" style={{minWidth: '12rem'}}/>
                    <Column field="Status" header="Status" style={{minWidth: '12rem'}}/>
                    <Column header="Image" style={{minWidth: '12rem'}} body={(p) => {
                        return (
                            <img
                                src={p.Image ? ImageLink + "Customer/" + p.Image : 'https://static.vecteezy.com/system/resources/thumbnails/005/545/335/small/user-sign-icon-person-symbol-human-avatar-isolated-on-white-backogrund-vector.jpg'}
                                style={{width: "80px", height: "80px", borderRadius: "50%"}}/>
                        )
                    }}/>
                    <Column header="Approve" style={{minWidth: '6rem'}} body={(p) => {
                        const acceptDelete = async () => {
                            var myHeaders = new Headers();
                            myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
                            var formdata = new FormData();
                            formdata.append("page", "ActivateCustomer");
                            formdata.append("id", p.id);
                            var requestOptions = {
                                method: 'POST',
                                headers: myHeaders,
                                body: formdata,
                                redirect: 'follow'
                            };

                            await fetch(`${AppLink}Customer.php`, requestOptions)
                                .then(response => response.json())
                                .then(result => {
                                    console.log(result);
                                    setLoading(false);
                                    if (result.status == "1") {
                                        // let Data = result.Data;
                                        LoadData();
                                        showSuccess("Action Perform Successfully");
                                    } else {
                                        showError();
                                        console.log("Server Error. Please Try Again Later.(Code : 403)");
                                    }
                                })
                                .catch(error => console.log('error', error));
                        }
                        return (
                            <Button icon={'pi pi-check'} severity={'success'} onClick={async (event) => {
                                confirmPopup({
                                    target: event.currentTarget,
                                    message: 'Do you want to approve this record?',
                                    icon: 'pi pi-info-circle',
                                    acceptClassName: 'p-button-success',
                                    accept() {
                                        acceptDelete()
                                    },
                                });


                            }}/>
                        )
                    }}/>
                </DataTable>
            </Card>
        </section>
    );
}
